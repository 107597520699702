import React, { useState, useEffect } from "react";
import "./masterjson.scss";
import Layout from "../../layout/Layout/Layout";
import Table from "../../components/Tables/TableMasterJson";
import Pagination from "../../components/Pagination/Pagination";
import { useMasterJson } from "../../hooks/useMasterJson";
import Spinner from "../../components/Spinner/Spinner";

const MasterJson = () => {
  const [products, setProducts] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [searchKeyword, setSearchKeyword] = useState("");
  const { getAllProducts, isLoading, deleteProductById } = useMasterJson();

  const tableProducts = {
    title: "MasterJSON CRUD",
    // headerBtn: "Add new product",
    tableTh: [
      "ID",
      "CODE",
      "NAME",
      "CATEGORY",
      "MODULE",
      "SUPERGROUP",
      "DERMO_FORM",
      "IMAGE",
      "ACTIONS", // Add a column for actions
    ],
  };

  useEffect(() => {
    const fetchProducts = async () => {
      console.log("calling products list");
      try {
        const data = await getAllProducts(currentPage, itemsPerPage, searchKeyword);
        setProducts(data.products);
        setTotalCount(data.totalCount);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, [currentPage, itemsPerPage, searchKeyword]);

  const updatedProductData = (updatedData) => {
    const index = products.findIndex(
      (product) => product.PRODUCT_ID === updatedData.PRODUCT_ID
    );

    if (index !== -1) {
      const updatedProducts = [...products];
      updatedProducts[index] = updatedData;
      setProducts(updatedProducts);
    }
  };

  const handleDeleteProduct = async (productId) => {
    await deleteProductById(productId);
    setProducts((prevProducts) =>
      prevProducts.filter((product) => product.PRODUCT_ID !== productId)
    );
  };

  const totalPages = Math.ceil(totalCount / itemsPerPage);

  return (
    <Layout>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Table
            title={tableProducts.title}
            products={products}
            tableData={tableProducts}
            updatedData={updatedProductData}
            setSearchKeyword={setSearchKeyword}
            onDelete={handleDeleteProduct} // Pass the delete function
          />
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
          />
        </>
      )}
    </Layout>
  );
};

export default MasterJson;
