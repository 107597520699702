import axios from "axios";
import * as Constants from "../../utils/constants";

export const getProducts = async (token, page = 1, itemsPerPage = 20, search = "") => {
  try {
    const response = await axios.get(Constants.GET_PRODUCTS, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: page,
        per_page: itemsPerPage,
        search: search,
      },
    });
    return response;
  } catch (error) {
    return error.message;
  }
};

export const updateProduct = async (token, productId, newProductData) => {
  try {
    const response = await axios.put(`${Constants.UPDATE_PRODUCT}/${productId}`, newProductData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return error.message;
  }
};

export const createProduct = async (token, productData) => {
  try {
    const response = await axios.post(Constants.CREATE_PRODUCT, productData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return error.message;
  }
};

export const deleteProduct = async (token, productId) => {
  try {
    const response = await axios.delete(`${Constants.DELETE_PRODUCT}/${productId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return error.message;
  }
};
