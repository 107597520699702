import { useState } from "react";
import { Modal, Form, Spinner, Button, Alert } from "react-bootstrap";
import { FaRegEdit } from "react-icons/fa";
import { Tooltip } from "@mui/material";
import uuid from "react-uuid";
import { useMasterJson } from "../../hooks/useMasterJson"; // Import the correct hook

function ProductEditModal({ titleText, btnText, formData, updatedData }) {
  const [show, setShow] = useState(false);
  const [productName, setProductName] = useState(formData.PRODUCT_NAME || "");
  const [productCode, setProductCode] = useState(formData.PRODUCT_CODE || "");
  const [category, setCategory] = useState(formData.CATEGORY || "");
  const [module, setModule] = useState(formData.MODULE || "");
  const [supergroup, setSupergroup] = useState(formData.SUPERGROUP || "");
  const [dermoForm, setDermoForm] = useState(formData.DERMO_FORM || "");
  const [brand, setBrand] = useState(formData.BRAND || "");
  const [dermoSkin, setDermoSkin] = useState(formData.DERMO_SKIN || "");
  const [packType, setPackType] = useState(formData.PACK_TYPE || "");
  const [manufacturer, setManufacturer] = useState(formData.MANUFACTURER || "");
  const [activeIngredient, setActiveIngredient] = useState(formData.ACTIVE_INGREDIENT || "");
  const [administration, setAdministration] = useState(formData.ADMINISTRATION || "");
  const [administrationRoute, setAdministrationRoute] = useState(formData.ADMINISTRATION_ROUTE || "");
  const [atcCode, setAtcCode] = useState(formData.ATC_CODE || "");
  const [code1, setCode1] = useState(formData.CODE1 || "");
  const [code1Description, setCode1Description] = useState(formData.CODE1_DESCRIPTION || "");
  const [code2, setCode2] = useState(formData.CODE2 || "");
  const [code2Description, setCode2Description] = useState(formData.CODE2_DESCRIPTION || "");
  const [code3, setCode3] = useState(formData.CODE3 || "");
  const [code3Description, setCode3Description] = useState(formData.CODE3_DESCRIPTION || "");
  const [code4, setCode4] = useState(formData.CODE4 || "");
  const [code4Description, setCode4Description] = useState(formData.CODE4_DESCRIPTION || "");
  const [codeDescription, setCodeDescription] = useState(formData.CODE_DESCRIPTION || "");
  const [depth, setDepth] = useState(formData.DEPTH || "");
  const [distributors, setDistributors] = useState(formData.DISTRIBUTORS || "");
  const [ean, setEan] = useState(formData.EAN || "");
  const [formula, setFormula] = useState(formData.FORMULA || "");
  const [fsSubstance, setFsSubstance] = useState(formData.FS_SUBSTANCE || "");
  const [height, setHeight] = useState(formData.HEIGHT || "");
  const [images, setImages] = useState(formData.IMAGES || "");
  const [maHolders, setMaHolders] = useState(formData.MA_HOLDERS || "");
  const [medAction, setMedAction] = useState(formData.MED_ACTION || "");
  const [medTarget, setMedTarget] = useState(formData.MED_TARGET || "");
  const [milkType, setMilkType] = useState(formData.MILK_TYPE || "");
  const [otc, setOtc] = useState(formData.OTC || "");
  const [pharmaceuticalForm, setPharmaceuticalForm] = useState(formData.PHARMACEUTICAL_FORM || "");
  const [pillType, setPillType] = useState(formData.PILL_TYPE || "");
  const [width, setWidth] = useState(formData.WIDTH || "");

  const [spinnerOn, setSpinnerOn] = useState(false);
  const [alert, setAlert] = useState(null);

  const { updateProducts } = useMasterJson(); // Use the correct hook

  const handleClose = () => {
    setProductName(formData.PRODUCT_NAME);
    setProductCode(formData.PRODUCT_CODE);
    setCategory(formData.CATEGORY);
    setModule(formData.MODULE);
    setSupergroup(formData.SUPERGROUP);
    setDermoForm(formData.DERMO_FORM);
    setBrand(formData.BRAND);
    setDermoSkin(formData.DERMO_SKIN);
    setPackType(formData.PACK_TYPE);
    setManufacturer(formData.MANUFACTURER);
    setActiveIngredient(formData.ACTIVE_INGREDIENT);
    setAdministration(formData.ADMINISTRATION);
    setAdministrationRoute(formData.ADMINISTRATION_ROUTE);
    setAtcCode(formData.ATC_CODE);
    setCode1(formData.CODE1);
    setCode1Description(formData.CODE1_DESCRIPTION);
    setCode2(formData.CODE2);
    setCode2Description(formData.CODE2_DESCRIPTION);
    setCode3(formData.CODE3);
    setCode3Description(formData.CODE3_DESCRIPTION);
    setCode4(formData.CODE4);
    setCode4Description(formData.CODE4_DESCRIPTION);
    setCodeDescription(formData.CODE_DESCRIPTION);
    setDepth(formData.DEPTH);
    setDistributors(formData.DISTRIBUTORS);
    setEan(formData.EAN);
    setFormula(formData.FORMULA);
    setFsSubstance(formData.FS_SUBSTANCE);
    setHeight(formData.HEIGHT);
    setImages(formData.IMAGES);
    setMaHolders(formData.MA_HOLDERS);
    setMedAction(formData.MED_ACTION);
    setMedTarget(formData.MED_TARGET);
    setMilkType(formData.MILK_TYPE);
    setOtc(formData.OTC);
    setPharmaceuticalForm(formData.PHARMACEUTICAL_FORM);
    setPillType(formData.PILL_TYPE);
    setWidth(formData.WIDTH);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSpinnerOn(true);
    const updatedProductData = {
      PRODUCT_ID: formData.PRODUCT_ID,
      PRODUCT_CODE: productCode,
      PRODUCT_NAME: productName,
      CATEGORY: category,
      MODULE: module,
      SUPERGROUP: supergroup,
      DERMO_FORM: dermoForm,
      BRAND: brand,
      DERMO_SKIN: dermoSkin,
      PACK_TYPE: packType,
      MANUFACTURER: manufacturer,
      ACTIVE_INGREDIENT: activeIngredient,
      ADMINISTRATION: administration,
      ADMINISTRATION_ROUTE: administrationRoute,
      ATC_CODE: atcCode,
      CODE1: code1,
      CODE1_DESCRIPTION: code1Description,
      CODE2: code2,
      CODE2_DESCRIPTION: code2Description,
      CODE3: code3,
      CODE3_DESCRIPTION: code3Description,
      CODE4: code4,
      CODE4_DESCRIPTION: code4Description,
      CODE_DESCRIPTION: codeDescription,
      DEPTH: depth,
      DISTRIBUTORS: distributors,
      EAN: ean,
      FORMULA: formula,
      FS_SUBSTANCE: fsSubstance,
      HEIGHT: height,
      IMAGES: images,
      MA_HOLDERS: maHolders,
      MED_ACTION: medAction,
      MED_TARGET: medTarget,
      MILK_TYPE: milkType,
      OTC: otc,
      PHARMACEUTICAL_FORM: pharmaceuticalForm,
      PILL_TYPE: pillType,
      WIDTH: width,
    };
    const response = await updateProducts(formData.PRODUCT_ID, updatedProductData);
    if (response) {
      setSpinnerOn(false);
      updatedData(updatedProductData);
      setShow(false);
    } else {
      setSpinnerOn(false);
      setAlert({
        variant: "danger",
        message: "Error updating the product. Please try again.",
      });
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImages(reader.result.split(',')[1]);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <Tooltip
        title="Edit Product"
        placement="top"
        arrow
        leaveDelay={20}
      >
        <button action="edit" onClick={handleShow}>
          <FaRegEdit />
        </button>
      </Tooltip>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{titleText}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {alert && (
            <Alert
              variant={alert.variant}
              onClose={() => setAlert(null)}
              dismissible
            >
              {alert.message}
            </Alert>
          )}
          <Form
            id="updateProductForm"
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <div className="row">
              <Form.Group className="mb-3 col-md-4" controlId={uuid()}>
                <Form.Label>Product ID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={formData.PRODUCT_ID}
                  disabled
                  readOnly
                />
              </Form.Group>
              <Form.Group className="mb-3 col-md-4" controlId={uuid()}>
                <Form.Label>Product Code</Form.Label>
                <Form.Control
                  type="text"
                  value={productCode}
                  onChange={(e) => setProductCode(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3 col-md-4" controlId={uuid()}>
                <Form.Label>Product Name</Form.Label>
                <Form.Control
                  type="text"
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                />
              </Form.Group>
            
            
              <Form.Group className="mb-3 col-md-4" controlId={uuid()}>
                <Form.Label>Category</Form.Label>
                <Form.Control
                  type="text"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3 col-md-4" controlId={uuid()}>
                <Form.Label>Module</Form.Label>
                <Form.Control
                  type="text"
                  value={module}
                  onChange={(e) => setModule(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3 col-md-4" controlId={uuid()}>
                <Form.Label>Supergroup</Form.Label>
                <Form.Control
                  type="text"
                  value={supergroup}
                  onChange={(e) => setSupergroup(e.target.value)}
                />
              </Form.Group>
            
            
              <Form.Group className="mb-3 col-md-4" controlId={uuid()}>
                <Form.Label>Dermo Form</Form.Label>
                <Form.Control
                  type="text"
                  value={dermoForm}
                  onChange={(e) => setDermoForm(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3 col-md-4" controlId={uuid()}>
                <Form.Label>Brand</Form.Label>
                <Form.Control
                  type="text"
                  value={brand}
                  onChange={(e) => setBrand(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3 col-md-4" controlId={uuid()}>
                <Form.Label>Dermo Skin</Form.Label>
                <Form.Control
                  type="text"
                  value={dermoSkin}
                  onChange={(e) => setDermoSkin(e.target.value)}
                />
              </Form.Group>
            
            
              <Form.Group className="mb-3 col-md-4" controlId={uuid()}>
                <Form.Label>Pack Type</Form.Label>
                <Form.Control
                  type="text"
                  value={packType}
                  onChange={(e) => setPackType(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3 col-md-4" controlId={uuid()}>
                <Form.Label>Manufacturer</Form.Label>
                <Form.Control
                  type="text"
                  value={manufacturer}
                  onChange={(e) => setManufacturer(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3 col-md-4" controlId={uuid()}>
                <Form.Label>Active Ingredient</Form.Label>
                <Form.Control
                  type="text"
                  value={activeIngredient}
                  onChange={(e) => setActiveIngredient(e.target.value)}
                />
              </Form.Group>
            
            
              <Form.Group className="mb-3 col-md-4" controlId={uuid()}>
                <Form.Label>Administration</Form.Label>
                <Form.Control
                  type="text"
                  value={administration}
                  onChange={(e) => setAdministration(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3 col-md-4" controlId={uuid()}>
                <Form.Label>Administration Route</Form.Label>
                <Form.Control
                  type="text"
                  value={administrationRoute}
                  onChange={(e) => setAdministrationRoute(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3 col-md-4" controlId={uuid()}>
                <Form.Label>ATC Code</Form.Label>
                <Form.Control
                  type="text"
                  value={atcCode}
                  onChange={(e) => setAtcCode(e.target.value)}
                />
              </Form.Group>
            
            
              <Form.Group className="mb-3 col-md-4" controlId={uuid()}>
                <Form.Label>Code 1</Form.Label>
                <Form.Control
                  type="text"
                  value={code1}
                  onChange={(e) => setCode1(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3 col-md-4" controlId={uuid()}>
                <Form.Label>Code 1 Description</Form.Label>
                <Form.Control
                  type="text"
                  value={code1Description}
                  onChange={(e) => setCode1Description(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3 col-md-4" controlId={uuid()}>
                <Form.Label>Code 2</Form.Label>
                <Form.Control
                  type="text"
                  value={code2}
                  onChange={(e) => setCode2(e.target.value)}
                />
              </Form.Group>
            
            
              <Form.Group className="mb-3 col-md-4" controlId={uuid()}>
                <Form.Label>Code 2 Description</Form.Label>
                <Form.Control
                  type="text"
                  value={code2Description}
                  onChange={(e) => setCode2Description(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3 col-md-4" controlId={uuid()}>
                <Form.Label>Code 3</Form.Label>
                <Form.Control
                  type="text"
                  value={code3}
                  onChange={(e) => setCode3(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3 col-md-4" controlId={uuid()}>
                <Form.Label>Code 3 Description</Form.Label>
                <Form.Control
                  type="text"
                  value={code3Description}
                  onChange={(e) => setCode3Description(e.target.value)}
                />
              </Form.Group>
            
            
              <Form.Group className="mb-3 col-md-4" controlId={uuid()}>
                <Form.Label>Code 4</Form.Label>
                <Form.Control
                  type="text"
                  value={code4}
                  onChange={(e) => setCode4(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3 col-md-4" controlId={uuid()}>
                <Form.Label>Code 4 Description</Form.Label>
                <Form.Control
                  type="text"
                  value={code4Description}
                  onChange={(e) => setCode4Description(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3 col-md-4" controlId={uuid()}>
                <Form.Label>Code Description</Form.Label>
                <Form.Control
                  type="text"
                  value={codeDescription}
                  onChange={(e) => setCodeDescription(e.target.value)}
                />
              </Form.Group>
            
            
              <Form.Group className="mb-3 col-md-4" controlId={uuid()}>
                <Form.Label>Depth</Form.Label>
                <Form.Control
                  type="text"
                  value={depth}
                  onChange={(e) => setDepth(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3 col-md-4" controlId={uuid()}>
                <Form.Label>Distributors</Form.Label>
                <Form.Control
                  type="text"
                  value={distributors}
                  onChange={(e) => setDistributors(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3 col-md-4" controlId={uuid()}>
                <Form.Label>EAN</Form.Label>
                <Form.Control
                  type="text"
                  value={ean}
                  onChange={(e) => setEan(e.target.value)}
                />
              </Form.Group>
            
            
              <Form.Group className="mb-3 col-md-4" controlId={uuid()}>
                <Form.Label>Formula</Form.Label>
                <Form.Control
                  type="text"
                  value={formula}
                  onChange={(e) => setFormula(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3 col-md-4" controlId={uuid()}>
                <Form.Label>FS Substance</Form.Label>
                <Form.Control
                  type="text"
                  value={fsSubstance}
                  onChange={(e) => setFsSubstance(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3 col-md-4" controlId={uuid()}>
                <Form.Label>Height</Form.Label>
                <Form.Control
                  type="text"
                  value={height}
                  onChange={(e) => setHeight(e.target.value)}
                />
              </Form.Group>
            
            
              <Form.Group className="mb-3 col-md-4" controlId={uuid()}>
                <Form.Label>Images</Form.Label>
                {images && (
                  <img src={`data:image/jpeg;base64,${images}`} alt="Product" style={{ maxWidth: '100px', maxHeight: '100px' }} />
                )}
                <Form.Control
                  type="file"
                  onChange={handleImageUpload}
                />
              </Form.Group>
              <Form.Group className="mb-3 col-md-4" controlId={uuid()}>
                <Form.Label>MA Holders</Form.Label>
                <Form.Control
                  type="text"
                  value={maHolders}
                  onChange={(e) => setMaHolders(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3 col-md-4" controlId={uuid()}>
                <Form.Label>Med Action</Form.Label>
                <Form.Control
                  type="text"
                  value={medAction}
                  onChange={(e) => setMedAction(e.target.value)}
                />
              </Form.Group>
            
            
              <Form.Group className="mb-3 col-md-4" controlId={uuid()}>
                <Form.Label>Med Target</Form.Label>
                <Form.Control
                  type="text"
                  value={medTarget}
                  onChange={(e) => setMedTarget(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3 col-md-4" controlId={uuid()}>
                <Form.Label>Milk Type</Form.Label>
                <Form.Control
                  type="text"
                  value={milkType}
                  onChange={(e) => setMilkType(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3 col-md-4" controlId={uuid()}>
                <Form.Label>OTC</Form.Label>
                <Form.Control
                  type="text"
                  value={otc}
                  onChange={(e) => setOtc(e.target.value)}
                />
              </Form.Group>
            
            
              <Form.Group className="mb-3 col-md-4" controlId={uuid()}>
                <Form.Label>Pharmaceutical Form</Form.Label>
                <Form.Control
                  type="text"
                  value={pharmaceuticalForm}
                  onChange={(e) => setPharmaceuticalForm(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3 col-md-4" controlId={uuid()}>
                <Form.Label>Pill Type</Form.Label>
                <Form.Control
                  type="text"
                  value={pillType}
                  onChange={(e) => setPillType(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3 col-md-4" controlId={uuid()}>
                <Form.Label>Width</Form.Label>
                <Form.Control
                  type="text"
                  value={width}
                  onChange={(e) => setWidth(e.target.value)}
                />
              </Form.Group>
            </div> 
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {spinnerOn ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            <>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" form="updateProductForm" type="submit">
                {btnText}
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ProductEditModal;
