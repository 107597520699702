//API URLs

const BASE_URL = "https://d10g49pvcautoa.cloudfront.net";
//const BASE_URL = "http://shelftrack.catalink.eu:2005";
//const BASE_URL = process.env.BASE_URL;
// const BASE_URL = "http://127.0.0.1:6567";

// authentication endpoints
export const LOGIN = `${BASE_URL}/auth/login`;
export const LOGOUT = `${BASE_URL}/auth/logout`;
export const VERIFY_TOKEN = `${BASE_URL}/auth/verify-token`;

// store-related endpoints
export const GET_STORES = `${BASE_URL}/stores/get-stores`;
export const UPDATE_STORE = `${BASE_URL}/stores/update-store`;
export const CREATE_STORE = `${BASE_URL}/stores/create-store`;
export const GET_GONDOLAS = `${BASE_URL}/stores/get-gondolas`;
export const GET_SECTIONS = `${BASE_URL}/stores/get-sections`;
export const GET_SHELVES = `${BASE_URL}/stores/get-shelves`;
export const SALES = `${BASE_URL}/stores/upload-sales-file`;
export const CONFIG = `${BASE_URL}/stores/store-configuration`;

// user-related endpoints
export const GET_USERS = `${BASE_URL}/users/get-users`;
export const UPDATE_USERS = `${BASE_URL}/users/update-user`;
export const CREATE_USER = `${BASE_URL}/users/create-user`;
export const GET_UNAMES = `${BASE_URL}/users/get-usernames`;
export const DELETE_USER = `${BASE_URL}/users/delete-user`;

// master file
export const MASTER_FILE = `${BASE_URL}/products/master-file`;
export const LAST_UPDATED = `${BASE_URL}/products/last-updated-master`;
export const ADD_NEW_PROD = `${BASE_URL}/products/add-new-product`;

// planogram
export const PLANOGRAM_SHELVES = `${BASE_URL}/planogram/planogram-shelves`;
export const CROPPED_IMAGES = `${BASE_URL}/planogram/cropped-images`;
export const PLACEHOLDERS = `${BASE_URL}/planogram/placeholders`;
export const IMG_FILENAMES = `${BASE_URL}/planogram/img-filenames`;
export const CREATE_PLANO_IMG = `${BASE_URL}/planogram/create-planogram-images`;
export const GET_PLANO_IMG = `${BASE_URL}/planogram/get-planogram-images`;
export const UPLOAD_IMG = `${BASE_URL}/planogram/upload-new-image`;

// exports
export const EXPORT_SALES = `${BASE_URL}/exports/get-sales-file`;
export const EXPORT_MASTER = `${BASE_URL}/products/get-new-master`;

export const GET_PRODUCTS = `${BASE_URL}/products/product-list`;
export const UPDATE_PRODUCT = `${BASE_URL}/products/update-product`;
export const CREATE_PRODUCT = `${BASE_URL}/products/create-product`;
export const DELETE_PRODUCT = `${BASE_URL}/products/delete-product`;
// COLORS
export const PRIMARY_COLOR = "#FF4E16";

//NAMES
export const BRAND = "ShelfTrack";
