import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { useAuthContext } from "./hooks/useAuthContext";

import Login from "./pages/Login/Login";
import Home from "./pages/Home/Home";
import Stores from "./pages/Stores/Stores";
import Users from "./pages/Users/Users";
import Products from "./pages/Products/Products";
import Exports from "./pages/Exports/Exports";
import Planogram from "./pages/Planogram/Planogram";
import MasterJson from "./pages/MasterJson/MasterJson";

function App() {
  const { user } = useAuthContext();
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={user ? <Home /> : <Navigate to="/login" />}
          />
          <Route
            path="/login"
            element={!user ? <Login /> : <Navigate to="/" />}
          />
          <Route
            path="/stores"
            element={user ? <Stores /> : <Navigate to="/login" />}
          />
          <Route
            path="/users"
            element={user ? <Users /> : <Navigate to="/login" />}
          />
          <Route
            path="/products"
            element={user ? <Products /> : <Navigate to="/login" />}
          />
          <Route
            path="/masterjson"
            element={user ? <MasterJson /> : <Navigate to="/login" />}
          />

          <Route
            path="/planogram"
            element={user ? <Planogram /> : <Navigate to="/login" />}
          />
          <Route
            path="/exports"
            element={user ? <Exports /> : <Navigate to="/login" />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
