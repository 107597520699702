import { useState } from "react";
import { useLocalStorage } from "./useLocalStorage";
import {
  getProducts,
  updateProduct,
  createProduct,
  deleteProduct, // Import the deleteProduct function
} from "../services/api/productsService";

export const useMasterJson = () => {
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { getItem } = useLocalStorage();

  const getAllProducts = async (page = 1, itemsPerPage = 20, search = "") => {
    setError(false);
    setIsLoading(true);

    try {
      const response = await getProducts(getItem("token"), page, itemsPerPage, search);
      setIsLoading(false);
      return {
        products: response.data.products,
        totalCount: response.data.total_count,
      };
    } catch (error) {
      setIsLoading(false);
      setError(true);
    }
  };

  const updateProducts = async (productId, newProductData) => {
    setError(false);

    try {
      const response = await updateProduct(getItem("token"), productId, newProductData);
      return response.data;
    } catch (error) {
      setError(true);
    }
  };

  const createNewProduct = async (productData) => {
    setError(false);
    try {
      const response = await createProduct(getItem("token"), productData);
      return response.data;
    } catch (error) {
      setError(true);
    }
  };

  const deleteProductById = async (productId) => {
    setError(false);
    try {
      const response = await deleteProduct(getItem("token"), productId);
      return response.data;
    } catch (error) {
      setError(true);
    }
  };

  return {
    getAllProducts,
    updateProducts,
    createNewProduct,
    deleteProductById, // Expose the delete function
    isLoading,
    error,
  };
};
