import React, { useState } from "react";
import "./table.scss";
import { GiConfirmed, GiCancel } from "react-icons/gi";
import { FaPlay, FaTrash, FaPlus } from "react-icons/fa";
import { Grid, Tooltip } from "@mui/material";
import { Modal, Button } from "react-bootstrap";
import ProductEditModal from "../Modal/ProductEditModal";
import { Alert } from "react-bootstrap";
import NewProductModal from "../Modal/NewProductModal"; // Import the new product modal

const TableStores = ({
  title,
  products,
  tableData,
  updatedData,
  setSearchKeyword,
  onDelete, // Accept the onDelete function as a prop
}) => {
  const tableTh = tableData.tableTh;
  const [search, setSearch] = useState("");
  const [alert, setAlert] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [showNewProductModal, setShowNewProductModal] = useState(false);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setSearchKeyword(search);
    }
  };

  const handleDelete = (productId) => {
    setProductToDelete(productId);
    setShowConfirm(true);
  };

  const confirmDelete = () => {
    if (productToDelete) {
      onDelete(productToDelete);
      setShowConfirm(false);
    }
  };

  const cancelDelete = () => {
    setShowConfirm(false);
    setProductToDelete(null);
  };

  const handleNewProduct = () => {
    setShowNewProductModal(true);
  };

  const closeNewProductModal = () => {
    setShowNewProductModal(false);
  };

  return (
    <>
      {alert && (
        <Alert
          variant={alert.variant}
          onClose={() => setAlert(null)}
          dismissible
        >
          {alert.message}
        </Alert>
      )}
      <div className="myTableContainer">
        <div className="tableHeader">
          <p className="tableTitle">{title.toUpperCase()}</p>
          <div className="tableSearchWrapper">
            <input
              className="tableInput"
              placeholder="Search"
              value={search}
              onChange={handleSearchChange}
              onKeyDown={handleKeyDown}
            ></input>
          </div>
          <Button variant="primary" onClick={handleNewProduct} style={{ marginLeft: '10px' }}>
            <FaPlus /> New Product
          </Button>
        </div>
        <div className="tableSection">
          <table className="tableWrap">
            <thead className="tableHead">
              <tr className="tableTr">
                {tableTh.map((title, index) => (
                  <th className="tableTh" key={index}>
                    {title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {products.map((entry, index) => (
                <tr key={index}>
                  <td>{entry.PRODUCT_ID}</td>
                  <td>{entry.PRODUCT_CODE}</td>
                  <td>{entry.PRODUCT_NAME}</td>
                  <td>{entry.CATEGORY}</td>
                  <td>{entry.MODULE}</td>
                  <td>{entry.SUPERGROUP}</td>
                  <td>{entry.DERMO_FORM}</td>
                  <td>
                    {entry.IMAGES ? (
                      <img
                        src={`data:image/jpeg;base64,${entry.IMAGES}`}
                        alt={entry.PRODUCT_NAME}
                        style={{ maxWidth: '100px', maxHeight: '100px' }}
                      />
                    ) : (
                      <span>NO IMAGE</span>
                    )}
                  </td>
                  <td>
                    <Grid
                      className="myCustomGrid"
                      container
                      spacing={0.5}
                      justifyContent="flex-end"
                    >
                      <Grid item>
                        <ProductEditModal
                          titleText={"Edit Product"}
                          btnText={"Update"}
                          formData={entry}
                          updatedData={updatedData}
                        />
                      </Grid>
                      <Grid item>
                        <Tooltip title="Delete">
                          <button onClick={() => handleDelete(entry.PRODUCT_ID)} style={{ cursor: 'pointer', background: 'darkred' }}>
                            <FaTrash
                              style={{ cursor: 'pointer', color: 'white' }}
                            />
                          </button>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Confirmation Modal */}
      <Modal show={showConfirm} onHide={cancelDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this product?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelDelete}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* New Product Modal */}
      <NewProductModal
        show={showNewProductModal}
        handleClose={closeNewProductModal}
      />
    </>
  );
};

export default TableStores;
